import React from "react"
import TemplatePageIndustriaEducacion from "@components/pageIndustrias/pageEducacion/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Plataforma omnicanal con IA para instituciones educativas</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/industria/educacion/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Explora cómo nuestra plataforma omnicanal con IA está transformando la comunicación y la gestión en instituciones educativas, optimizando procesos y experiencias."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Plataforma omnicanal con IA para instituciones educativas"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/industria/educacion/`}
      />
       <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Explora cómo nuestra plataforma omnicanal con IA está transformando la comunicación y la gestión en instituciones educativas, optimizando procesos y experiencias."
      />
    </Helmet>
    <TemplatePageIndustriaEducacion location={location} />
  </div>
)

export default IndexPage
