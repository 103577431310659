/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageIndustrias/pageEducacion/data.jsx"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageIndustrias/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionLeft from "@components/sharedComponents/accordionLeft/accordionLeft"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Experience from "@components/pageIndustrias/pageEducacion/componentExperience/experience"
import { TitleUnderlineLeftOne, TitleUnderlineLeftTwo } from "@components/pageIndustrias/pageEducacion/titleUnderline/titleUnderlineAccordionLeft"
import { TitleUnderlineCovertionsOne, TitleUnderlineCovertionsTwo } from "@components/pageIndustrias/pageEducacion/titleUnderline/titleUnderlineConvertions"
import TitleUnderlineTestimony from "@components/pageIndustrias/pageEducacion/titleUnderline/titleUnderlineTestimony"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import Conversions from "@components/pageIndustrias/components/conversions/conversions"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import ecommerce from "@components/sharedComponents/testimonies/images/iconos/ecommerce.svg"

const TemplatePageContactCenter = ({ location }) => {


    //Video youtube intro
    const [videoIntro, setVideoIntro] = useState(false)
    const openVideoIntro = value => {
        setVideoIntro(value)
    }

    //Video youtube
    const [video, setVideo] = useState(false)
    const openVideo = value => {
        setVideo(value)
    }

    //velocidad de entrada de textos y animaciones para desktop y mobile
    const windowsWidth = true


    const titleAccordionRight = <p>
        Contamos con las mejores funciones para que tus leads  <TitleUnderlineLeftOne underline="se conviertan" /> <TitleUnderlineLeftTwo underline=" en estudiantes" />
    </p>
    const titleTestimony = <p>
        Mejoras las interacciones de tu  <TitleUnderlineTestimony underline="institución educativa" /> con tecnología omnicanal en la nube
    </p>
    const titleConvertions = <p className="container-conversions-title">
        Potenciamos las operaciones de  <TitleUnderlineCovertionsOne underline="diferentes" /> <TitleUnderlineCovertionsOne underline="subsectores" /> de educación para mejorar las interacciones con los estudiantes
    </p>

    const titleExperience = <p className="container-experience-ecommerce-title">
        Nunca pierdas el contacto con los estudiantes y logra fidelizarlos con  <TitleUnderlineCovertionsOne underline="experiencias" /> <TitleUnderlineCovertionsTwo underline="personalizadas" />
    </p>



    const iconsSilder = i => {
        switch (i) {
            case 0:
                return finanzas
            case 1:
                return ecommerce
            default:
                return ""
        }
    }
    const titleSilder = i => {
        switch (i) {
            case 0:
                return "Finanzas"
            case 1:
                return "Ecommerce"
            default:
                return "Conoce otros casos de éxito"
        }
    }

    return (
        <div className="fnd">
            <section>
                <BannerCookies />
                <div className="banner-invisible-ebook" />
                <div>
                    <Header
                        path="/soluciones/"
                        location={location}
                        bannerCTA={true}
                        windowsWidth={windowsWidth}
                    />
                </div>
                
                {videoIntro ?
                    <>
                        <button
                            className="button-video-youtube-beex"
                            onClick={() => {
                                openVideoIntro(false)
                            }}
                        >
                            X
                        </button>
                        <div className="container-video-youtube-beex">
                            <div className="container-video-youtube-beex-background"></div>
                            <div className="container-video-youtube-beex-iframe">
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src="https://www.youtube.com/embed/wrJ8a1kO_Ao?autoplay=1"
                                    title="Beex Teams"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </> : ""}
                {video ?
                    <>
                        <button
                            className="button-video-youtube-beex"
                            onClick={() => {
                                openVideo(false)
                            }}
                        >
                            X
                        </button>
                        <div className="container-video-youtube-beex">
                            <div className="container-video-youtube-beex-background"></div>
                            <div className="container-video-youtube-beex-iframe">
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src="https://www.youtube.com/embed/wrJ8a1kO_Ao?autoplay=1"
                                    title="Beex Teams"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </> : ""}


                <div className="container">
                    <Start data={data.start} location={location} imageViewResponsive={false} openVideo={openVideoIntro}/>
                    <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Sé parte de las empresas del sector educación que conectaron mejor con sus estudiantes" />
                    <AccordionLeft data={data.accordionRight} title={titleAccordionRight} description={""} />
                    <BannerCTA
                        title="Reduce el tiempo de respuesta a las consultas de tus estudiantes"
                        text="Agilizamos todos los procesos de atención de tu entidad educativa de manera personalizada, con workflows e IA."
                        link="/hablemos/"
                        textButton="Hablar con un experto"
                    />
                    <Testimonies data={data.testimonies}
                        location={location}
                        title={titleTestimony}
                        iconsSilder={iconsSilder}
                        titleSilder={titleSilder}
                        buttonSilder={true} />
                    <Conversions data={data.conversions} title={titleConvertions} />
                    <Experience data={data.modules} title={titleExperience} />
                    <BannerCTA
                        title="Mejora la tasa de inscripción de tus cursos"
                        text="Te ayudamos a utilizar la mejor tecnología para mejorar las conversiones dentro de tu institución."
                        link="/hablemos/"
                        textButton="Hablar con un experto"
                    />
                    <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
                    <ArticleBlog data={data.articles} home={true} />
                    <BannerContact data={data.contacts} location={location} />
                    <Footer data={data.footer} location={location} />
                    <FooterMobile data={data.footer} location={location} />
                </div>
            </section>
        </div>
    )
}

export default TemplatePageContactCenter
