import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
       width="393" height="10" viewBox="0 0 393 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M72.9793 9.24792C64.8669 8.29203 5.43925 9.1265 1.1209 8.83206C-2.40413 8.44494 3.25365 1.85784 6.53018 0.896151C7.67868 0.550345 9.01176 0.504024 12.0651 0.673358C16.9064 0.98865 79.5214 0.610069 79.5214 0.610069C80.3474 0.816343 82.83 0.138081 83.2847 0.387601C84.7169 0.971077 186.206 0.527489 194.862 0.525108C200.109 0.523863 195.957 0.90397 202.133 0.765996C215.294 0.4928 243.976 0.89546 253.949 0.566921C261.931 0.2952 263.652 0.28834 271.311 0.555982C279.139 0.798765 320.764 0.62864 325.523 0.180653C326.688 0.0574437 327.582 0.104994 327.581 0.251234C327.581 0.283025 356.922 0.490809 357.056 0.605717C357.275 0.752702 358.169 0.825677 358.996 0.726754C360.532 0.579372 391.765 0.13232 392.253 0.559986C393.784 1.78599 389.464 6.97243 385.832 8.2254C382.115 9.5671 361.739 7.97823 345.353 8.73642L269.073 8.55989L252.722 9.01299C250.851 8.56791 243.643 9.64977 239.292 8.84655C237.454 8.52875 224.463 8.44009 221.594 8.76097C219.096 9.00682 152.638 8.13881 145.819 8.99945C133.673 8.22061 84.4024 9.1087 72.9793 9.24792Z" fill="#FFBA00"/>
      </svg> 
  </span>
)

export default titleUnderline
