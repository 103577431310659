import React, { useEffect, useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "@components/pageIndustrias/pageEducacion/images/imageExperience/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "@components/pageIndustrias/pageEducacion/images/imageExperience/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "@components/pageIndustrias/pageEducacion/images/imageExperience/imagesDesktop/imageThree.webp"
import imageFourDesktop from "@components/pageIndustrias/pageEducacion/images/imageExperience/imagesDesktop/imageFour.webp"

import imageOneMobile from "@components/pageIndustrias/pageEducacion/images/imageExperience/imagesMobile/imageOne.webp"
import imageTwoMobile from "@components/pageIndustrias/pageEducacion/images/imageExperience/imagesMobile/imageTwo.webp"
import imageThreeMobile from "@components/pageIndustrias/pageEducacion/images/imageExperience/imagesMobile/imageThree.webp"
import imageFourMobile from "@components/pageIndustrias/pageEducacion/images/imageExperience/imagesMobile/imageFour.webp"


const Experience = ({ title }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Seguimiento de solicitudes",
      description: "Automatiza el seguimiento de solicitudes de admisión, manteniendo a los solicitantes informados sobre el estado de su aplicación mediante actualizaciones y recordatorios automáticos.",
      image: imageOneMobile,
      background: "#EEEAFB"
    },
    {
      title: "Notificaciones personalizadas",
      description: "Envía notificaciones personalizadas sobre fechas importantes, eventos y actualizaciones académicas a estudiantes y padres, utilizando múltiples canales como correo electrónico, SMS o WhatsApp.",
      image: imageTwoMobile,
      background: "#FCEEEE"
    },
    {

      title: "Recordatorios de pagos",
      description: "Automatiza los recordatorios de pagos de matrículas y cuotas, enviando notificaciones oportunas a estudiantes y padres para asegurar el cumplimiento de los plazos.",
      image: imageThreeMobile,

      background: "#EEEAFB"
    },
    {
      title: "Seguimiento al estudiante",
      description: "Monitorea el progreso académico de los estudiantes y envía alertas tempranas a los consejeros académicos y a los mismos estudiantes cuando se identifiquen riesgos de bajo rendimiento.",
      image: imageFourMobile,
      background: "#FCEEEE"
    },
  ]

  return (
    <section className="container-experience-ecommerce">
     {title}

      {/* Contenedor de dos columnas */}
      <div className="container-experience-ecommerce-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-ecommerce-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
          <section >
            <p className="container-experience-ecommerce-cardsOne-card-title">Seguimiento de solicitudes</p>
            <p className="container-experience-ecommerce-cardsOne-card-description">
              Automatiza el seguimiento de solicitudes de admisión, manteniendo a los solicitantes informados sobre el estado de su aplicación mediante actualizaciones y recordatorios automáticos.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-ecommerce-cardsOne-card-image"
              src={imageOneDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-ecommerce-cardsOne-column">
          {/* columna */}
          <section className="container-experience-ecommerce-cardsOne-card" style={{ background: "#FCEEEE", padding: "32px 32px 20px" }}>
            <section>
              <p className="container-experience-ecommerce-cardsOne-card-title">Notificaciones personalizadas</p>
              <p className="container-experience-ecommerce-cardsOne-card-description">
                Envía notificaciones personalizadas sobre fechas importantes, eventos y actualizaciones académicas a estudiantes y padres, utilizando múltiples canales como correo electrónico, SMS o WhatsApp.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-ecommerce-cardsOne-card-image"
                src={imageTwoDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-ecommerce-cardsTwo">
        {/* una sola fila */}
        <section className="container-experience-ecommerce-cardsTwo-card" style={{ background: "#FCEEEE", padding: "32px 32px 0px" }}>
          <section >
            <p className="container-experience-ecommerce-cardsTwo-card-title">Recordatorios de pagos</p>
            <p className="container-experience-ecommerce-cardsTwo-card-description">
              Automatiza los recordatorios de pagos de matrículas y cuotas, enviando notificaciones oportunas a estudiantes y padres para asegurar el cumplimiento de los plazos.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-ecommerce-cardsTwo-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-ecommerce-cardsTwo-column">
          {/* columna */}
          <section className="container-experience-ecommerce-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-ecommerce-cardsTwo-card-title">Seguimiento al estudiante</p>
              <p className="container-experience-ecommerce-cardsTwo-card-description">
                Monitorea el progreso académico de los estudiantes y envía alertas tempranas a los consejeros académicos y a los mismos estudiantes cuando se identifiquen riesgos de bajo rendimiento.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-ecommerce-cardsTwo-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>


      <div className="container-experience-ecommerce-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-ecommerce-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-ecommerce-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-ecommerce-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                    <br />
                  </section>
                  <section>
                    <img
                      className="container-experience-ecommerce-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
